<script setup>
const leasing = useLeasingParams();

const duration = 300;
const maxSteps = 10;

let initiated = false;

const props = defineProps({
  value: {
    type: [String, Number],
    default: 0
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const vat = computed(() => leasing.refParams.value.client === 'entrepreneur' ? 1 : 1.23);

const currentValue = ref(parseValue(props.value));
const displayedCounter = ref(priceFormat(9999))

const incrementValue = (to) => new Promise(done => {
  setTimeout(() => {
    displayedCounter.value = priceFormat(parseValue(to));
    done(to);
  }, duration / maxSteps);
});

const animateCounter = async (to) => {
  const steps = Math.abs(to - currentValue.value) < maxSteps ? to - currentValue.value : maxSteps;
  const step = (to - currentValue.value) / steps;

  for (let i = 0; i < steps; i++) {
    let end = i === steps - 1 ? to : currentValue.value + (step * (i + 1));
    await incrementValue(end);
  }

  currentValue.value = to;
}

watch(
  () => props.value,
  (newValue) => {
    newValue = Math.round(newValue * vat.value);
    if(!initiated) {
      displayedCounter.value = priceFormat(newValue);
      initiated = true;
      return;
    }
    animateCounter(newValue);
  },
  {deep: true, immediate: true}
)

</script>

<template>
    <span :class="{'animate-pulse': props.loading}">{{ displayedCounter }}</span>

</template>
